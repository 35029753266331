<template>
  <div id="orderHistory">
    <!-- Table View -->
    <VueDatatable
      ref="myTable"
      :setupTable="setupTable"
      :advancedFilter="advancedFilter"
      :allowRefresh="true"
    >
      <template v-slot:thead>
        <tr>
          <th class="id-col">
            <span>ID</span>
          </th>
          <th>
            <b-form-select
              style="width: 100%"
              v-model="search.status"
              :options="statusOptions"
              @change="(v) => myTable.searching(v, 1)"
              size="sm"
            />
          </th>
          <th>
            <b-form-select
              v-model="search.service"
              :options="serviceOptions"
              @change="(v) => myTable.searching(v, 2)"
              size="sm"
            />
          </th>
          <th class="not-mobile">IMEI</th>
          <th class="not-mobile">Result</th>
          <th class="not-mobile">Submitted</th>
          <th class="not-mobile">Credit</th>
          <th class="none">Notes</th>
        </tr>
      </template>
    </VueDatatable>
  </div>
</template>

<script>
import VueDatatable from "@/components/util/vue-datatable/Index.vue";
import { setupTable, serviceOptions } from "@/datatable/order.datatable.js";
import { statusOptions } from "@/global/variables.js";
import { BFormSelect } from "bootstrap-vue";
import advancedFilter from "@/datatable/filter/order-history.filter.js";
export default {
  name: "OrderHistroy",
  data: () => ({
    dataTable: null,
    setupTable,
    search: {
      status: null,
      service: null,
    },
    statusOptions,
    advancedFilter,
    serviceOptions: [],
  }),
  async mounted() {
    this.dataTable = this.$refs.myTable;
    this.serviceOptions = await serviceOptions();
  },
  computed: {
    myTable() {
      return this.dataTable || {};
    },
  },
  components: {
    VueDatatable,
    BFormSelect,
  },
};
</script>

<style>
</style>
