import api from '@axios';

// FOR SERVICES
class Service {
	// Get specific service - ADMIN
	static async specific(id) {
		const { data } = await api.get(`/services/show/${id}`);
		return data;
	}
	// Get all services -- only active
	static async fetch(query = '') {
		const { data } = await api.get(`/services${query}`);
		return data;
	}
	// Add Service - ADMIN
	static async add(formData) {
		const { data } = await api.post('/services', formData);
		return data;
	}
	// Position Update - ADMIN
	static async position(from, to) {
		await api.put(`/services/${from}/${to}`);
	}
	// Update service - ADMIN
	static async update({ id, ...rest }) {
		await api.put(`/services/${id}`, rest);
	}
	// Update service - ADMIN
	static async phpServiceList() {
		const { data } = await api.get(`/services/fetch/php`);
		return data;
	}
	// get services reports - ADMIN
	static async reports(date) {
		const { data } = await api.post('/services/reports/get', { from: date[0], to: date[1] });
		return data;
	}
}

export default Service;
