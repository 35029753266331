import { serviceOptions } from "@/datatable/order.datatable"
import { serverUrl, statusOptions } from "@/global/variables"

export default {
    label: "Orders",
    fields: [
        // {
        //     type: "number",
        //     key: "id",
        //     label: "ID"
        // },
        {
            type: "select",
            key: "category",
            label: "Type",
            options: [{ text: 'Type', value: undefined }, { text: 'Check', value: 'CHECK' }, { text: 'Unlock', value: 'UNLOCK' }]
        },
        {
            type: "select",
            key: "status",
            label: "Status",
            options: statusOptions,
        },
        {
            type: "select",
            key: "service",
            label: "Service",
            options: [], // will be filled from other place
            fetch: serviceOptions
        },
        {
            type: "date",
            betweenPeriod: true, // start - end
        },
        {
            type: "textarea",
            label: 'Each IMEI by new line',
            key: "imeis",
            rows: 5
        }
    ],
    onSubmit(data, table, searching) {
        const { date, id, imeis, ...rest } = data;
        this.reset(table)
        // // Filter Date period
        if (date) {
            const { from, to } = date;
            (from || to) && table.ajax.url(serverUrl(`/api/orders?from=${from}&to=${to}`)).load();
        }
        // // Filter ID
        // id && searching && searching(id, 0)
        // // IMEIs filter
        imeis && table.column(3).search(imeis.split('\n').join("|"), true, false).draw();
        // // Other stuff
        const searchStr = Object.values(rest).join(" ");
        table.search(searchStr).draw();
    },
    reset(table) {
        table.column(0).search("").draw()
        table.column(3).search("").draw()
        table.search("").draw()
        table.ajax.url(serverUrl(`/api/orders`)).load();
    }
}